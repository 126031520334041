import React from 'react'
import { FaPhone } from "react-icons/fa6";


const OurServiceComponent = ({image,heading,desc}) => {
  return (
    <div className=" py-6 px-4 rounded-lg shadow-lg border">
    <div className=' flex flex-col gap-4  flex-1 h-full grow'>
        <div class="flex grow">
            <img src={image} className='transition-all ease-in hover:scale-90 cursor-pointer object-cover	'/>
        </div>
        <div className='leading-6 text-center font-normal text-3xl tracking-wider font-serif'>{heading}</div>
        <p className='leading-6 text-center text-md tracking-wider mt-3 opacity-80 flex grow'>{desc}</p>
        <div className='flex flex-col gap-y-2 items-center justify-center md:flex-row lg:flex-row mt-5 gap-x-2 '>
                            <div>
                               <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                            </div>
                         <div>
                             <a href="tel:+918838213944 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} /> +91 8838213944</span>
                              </a>
                         </div>
                      </div>

    </div>
  </div>
  )
}

export default OurServiceComponent
