import React from 'react';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { FaCar } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { FreeMode, Pagination, Navigation, Autoplay } from 'swiper/modules';
import chennai from '../../assets/images/services/Chennai_scroll.jpg';
import coimbatore from '../../assets/images/services/Coimbatore _scroll.jpg';
import trichy from '../../assets/images/services/Trichy.jpg';
import banglore from '../../assets/images/services/Bangalore_scroll.jpg';
import pondicherry from '../../assets/images/services/Pondicherry.jpg';
import bg from "../../assets/images/taxi_bg_1.jpg";
import bg1 from "../../assets/images/pattern_bg_5.jpg";
import primesedan from "../../assets/images/cars/etios.png";
import suv from "../../assets/images/cars/suv.png";
import primeSuv from "../../assets/images/cars/innova.png";
import sedan from "../../assets/images/cars/sedan.png"

const Package = () => {
  const divStyle={
    backgroundImage: `url( ${bg})`,
    backgroundSize: "auto",
    backgroundPosition: "top center",
    backgroundRepeat:"no-repeat"
  }
  const divStyle2={
    backgroundImage: `url( ${bg1})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat:"no-repeat"

  }
  return (
    <div className='flex items-center justify-center flex-col p-5 gap-y-3 md:p-16 lg:p-20 ' style={divStyle} id='tarrif'>
                              <h4 className=' text-lg font-medium primary-color text-center'>About Us</h4>

           <h1 className="text-center font-bold text-5xl text-white">Our pricing</h1>
      <Swiper
        loop={true}         
           autoplay={{
          delay: 3000, 
          disableOnInteraction: false // Autoplay will not be disabled after user interactions
        }}
        breakpoints={{
          340: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          700: {
            slidesPerView: 3,
            spaceBetween: 15
          }
        }}
        freeMode={true}
        // pagination={{
        //   clickable: true
        // }}
        // navigation={{
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // }} 
        modules={[FreeMode, Pagination, Navigation, Autoplay]}
        className='max-w-[100%] lg:max-w-[100%] p-2 lg:mt-20'
      >
        <SwiperSlide className='mb-20 '>
          <div className="grid grid-cols-1 bg-white px-5">
          <img src={primesedan} alt="Chennai" />
          <div className='relative bg-black flex flex-col gap-y-5  justify-around mt-8' style={divStyle2}>
              <h1 className='text-xl font-bold text-center text-white mt-24 uppercase'>Etios</h1>
             
              <div className='flex justify-between px-5 text-white'>
                <div className='text-md'>One Way</div>
                <div className='items-end text-md'>Rs.14</div>

              </div>
              <div className='flex justify-between px-5 text-white'>
                <div className='text-md'>Round Trip</div>
                <div className='items-end text-md'>Rs.13</div>

              </div>
              <div className='flex  flex-col md-flex-row lg:flex-row xl:flex-row mt-5 gap-2 items-center md:justify-center lg:justify-center xl:justify-center'>
                            <div>
                               <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                            </div>
                         <div>
                             <a href="tel:+918838213944 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} /> +91 8838213944</span>
                              </a>
                         </div>
                      </div>
            <div className='absolute top-[-25px] left-[35%] h-[100px] w-[100px] bg-[#0091ff] rounded-full flex items-center justify-center outline outline-white'><FaCar size={45} className=' text-white'/>
            </div>
          </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className='mb-20'>
        <div className="grid grid-cols-1 bg-white px-5">
          <img src={suv} alt="Chennai" className="w-[375px] "/>
          <div className='relative bg-black flex flex-col gap-y-5  justify-around mt-8' style={divStyle2}>
              <h1 className='text-xl font-bold text-center text-white mt-24 uppercase'>SUV</h1>
              {/* <div className='text-md text-center text-white'>Round Trip</div> */}
              <div className='flex justify-between px-5 text-white'>
                <div className='text-md'>One Way</div>
                <div className='items-end text-md'>Rs.19</div>

              </div>
              <div className='flex justify-between px-5 text-white'>
                <div className='text-md'>Round Trip</div>
                <div className='items-end text-md'>Rs.17</div>

              </div>
              <div className='flex  flex-col md-flex-row lg:flex-row xl:flex-row mt-5 gap-2 items-center md:justify-center lg:justify-center xl:justify-center '>
                            <div>
                               <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                            </div>
                         <div>
                             <a href="tel:+918838213944 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} /> +91 8838213944</span>
                              </a>
                         </div>
                      </div>
            <div className='absolute top-[-25px] left-[35%] h-[100px] w-[100px] bg-[#0091ff] rounded-full flex items-center justify-center outline outline-white'><FaCar size={45} className=' text-white'/>
            </div>
          </div>
          </div>

        </SwiperSlide>
        <SwiperSlide className='mb-20'>
        <div className="grid grid-cols-1 bg-white px-5">
          <img src={primeSuv} alt="Chennai" />
          <div className='relative bg-black flex flex-col gap-y-5  justify-around mt-8' style={divStyle2}>
              <h1 className='text-xl font-bold text-center text-white mt-24'>INNOVA</h1>
              {/* <div className='text-md text-center text-white'>One-Way</div> */}
              <div className='flex justify-between px-5 text-white'>
                <div className='text-md'>One Way</div>
                <div className='items-end text-md'>Rs.19</div>

              </div>
              <div className='flex justify-between px-5 text-white'>
                <div className='text-md'>Round Trip</div>
                <div className='items-end text-md'>Rs.19</div>

              </div>
              <div className='flex  flex-col md-flex-row lg:flex-row xl:flex-row mt-5 gap-2 items-center md:justify-center lg:justify-center xl:justify-center'>
                            <div>
                               <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                            </div>
                         <div>
                             <a href="tel:+918838213944 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} /> +91 8838213944</span>
                              </a>
                         </div>
                      </div>
            <div className='absolute top-[-25px] left-[35%] h-[100px] w-[100px] bg-[#0091ff] rounded-full flex items-center justify-center outline outline-white'><FaCar size={45} className=' text-white'/>
            </div>
          </div>
          </div>

        </SwiperSlide>
        <SwiperSlide className='mb-20'>
        <div className="grid grid-cols-1 bg-white px-5">
          <img src={sedan} alt="Chennai" />
          <div className='relative bg-black flex flex-col gap-y-5  justify-around mt-8' style={divStyle2}>
              <h1 className='text-xl font-bold text-center text-white mt-24 uppercase'>Sedan</h1>
              {/* <div className='text-md text-center text-white'>One-Way</div> */}
              <div className='flex justify-between px-5 text-white'>
                <div className='text-md'>One Way</div>
                <div className='items-end text-md'>Rs.14</div>

              </div>
              <div className='flex justify-between px-5 text-white'>
                <div className='text-md'>Round Trip</div>
                <div className='items-end text-md'>Rs.13</div>

              </div>
              <div className='flex  flex-col md-flex-row lg:flex-row xl:flex-row mt-5 gap-2 items-center md:justify-center lg:justify-center xl:justify-center'>
                            <div>
                               <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                            </div>
                         <div>
                             <a href="tel:+918838213944 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} /> +91 8838213944</span>
                              </a>
                         </div>
                      </div>
            <div className='absolute top-[-25px] left-[35%] h-[100px] w-[100px] bg-[#0091ff] rounded-full flex items-center justify-center outline outline-white'><FaCar size={45} className=' text-white'/>
            </div>
          </div>
          </div>

        </SwiperSlide>
        {/* <SwiperSlide className='mb-20'>
        <div className="grid grid-cols-1 bg-white px-5">
          <img src={suv} alt="Chennai" className="w-[330px] " />
          <div className='relative bg-black flex flex-col gap-y-5  justify-around mt-8' style={divStyle2}>
              <h1 className='text-xl font-bold text-center text-white mt-24'> Suv</h1>
              <div className='text-md text-center text-white'>One-Way</div>
              <div className='flex justify-between px-5 text-white'>
                <div className='text-md'>Starts From</div>
                <div className='items-end text-md'>Rs.19</div>

              </div>
              <div className=' flex justify-center p-3'>
                    <a href="#book_taxi_form" className='th-btn-reverse '>Book Now</a>
                </div>
            <div className='absolute top-[-25px] left-[35%] h-[100px] w-[100px] bg-[#0091ff] rounded-full flex items-center justify-center outline outline-white'><FaCar size={45} className=' text-white'/>
            </div>
          </div>
          </div>

        </SwiperSlide> */}

        {/* Navigation buttons */}
        {/* <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div> */}
      </Swiper>
    </div>
  );
};

export default Package;
