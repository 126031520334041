import React from "react";
import banner from "../assets/images/backgroundTaxi.jpg";
import Form from "./Form";
import { FaPhone } from "react-icons/fa6";
import FormDuplicate from "./FormDuplicate";
import img1 from "../assets/images/ser1.png";
import img2 from "../assets/images/ser2.png";
import img3 from "../assets/images/ser3.png";
import img4 from "../assets/images/ser4.png";
import callicon from "../assets/images/callnow.gif"
import { Link } from 'react-router-dom';
import headimg from '../assets/images/headerbackimg.png';
import carCard from "../assets/images/carCard.png";


const BookingForm = () => {
  const divStyle = {
    backgroundImage: `url( ${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat:"no-repeat"
    // animation :`( ${animate} ) 1s linear infinite`  ,  
    
  };

  return (
    <>
    <div className=" form_bg relative head_bg "  style={divStyle} >
          {/* <div className="grid grid-cols-1">
          <div className="bg-white  rounded-lg px-4  pb-4 order-1  md:order-0 lg:order-0" id="book_taxi_form">

            <Form />
            <FormDuplicate />
          </div>
          </div> */}
          {/* <div className="order-0 md:order-1 lg:order-1">
            <img src={banner}/>
            </div> */}
            <img src={headimg} className="absolute w-full object-fit:cover top-[85px] lg:w-[55%] right-[2px] "/>
            {/* <img src={carCard} className="absolute w-full object-fit:cover top-[85px] lg:w-[55%] right-[2px] "/> */}
           <div className="mx-auto mt-72 lg:mt-0 md:ml-[8%] sm:px-8 relative z-[3]" >
            {/* <p className=" text-white font-semibold text-lg">
              Book Drop Taxi Service with Madurai One Way Taxi
            </p> */}   <div></div>

                   <div className="text-center md:text-left lg:text-left text-md text-white">Madurai One Way Taxi</div>
                    <h3 className=" text-center md:text-left lg:text-left mt-4 font-bold text-white text-2xl md:text-5xl  lg:text-5xl ">
                    Superior Quality At Low Cost.
                    { " "}
        </h3>


            {/* <div className=" text-6xl text-white font-bold mt-4">
              Welcome to
            </div>
            <div className=" text-6xl text-white font-bold mt-4">
            Madurai One Way Taxi

 
            </div> */}
            <div className="hidden lg:inline-block md:inline-block text-center md:text-left lg:text-left mt-4 text-lg  md:text-xl lg:text-xl text-white">
              {" "}
              Since day one, Madurai One Way Taxi has been on the road 
              <br/>to client satisfaction by providing stress-free taxi service.

</div>
            <div className=" lg:hidden md:hidden text-center md:text-left lg:text-left mt-4 text-md  md:text-xl lg:text-xl text-white">
              {" "}
              Since day one, Madurai One Way Taxi has been on the road 
              to client satisfaction by providing stress-free taxi service.

</div>
<div className=' flex justify-center lg:justify-start md:justify-start mt-12 '>
                    <a href="#book_taxi_form" className='th-btn-reverse '>Book Now</a>
                </div>

            {/* <div className=" text-white font-bold text-lg mt-10 ">
            <Link to="tel:+918838213944">  <img src={callicon} /> </Link>
            </div> */}

          </div> 
        </div>
  

    {/* <div>
    <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Drop Cabs
            </p>
            <p>OneWay Drop</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Outstation Cabs
            </p>
            <p>Outstation Oneway,Round Trip</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Round Trip
            </p>
            <p>Local & Outstation</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Tour Packages
            </p>
            <p>Tamilnadu,Kerala,Andhra,Pondicherry,Telangana</p>
          </div>
        </div>
      </div>

    </div> */}
    </>

  );
};

export default BookingForm;
